html, body, #main {
    height: 100%;
}

/* 
 * Make toggle buttons in masthead always dark
 */
.berta-masthead .pf-v5-c-switch {
  align-items: center;
  --pf-v5-c-switch__input--not-checked__label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-switch__input--checked__label--Color: var(--pf-v5-global--Color--100);
}

.berta-masthead .pf-v5-c-toggle-group {
  --pf-v5-c-toggle-group__button--m-selected--BackgroundColor: var(--pf-v5-global--palette--blue-400);
  --pf-v5-c-toggle-group__button--focus--BackgroundColor: transparent;
  --pf-v5-c-toggle-group__button--hover--BackgroundColor: transparent;
}

.pf-v5-theme-dark .ws-theme-switch-full-page::before {
  background-color: var(--pf-v5-global--BackgroundColor--300);
}

body {
  --tooltip-background: rgba(0, 5, 11, 0.9);
  --tooltip-color: #fff;
  --tooltip-border-radius: 5px;
  --tooltip-spacing: 5px;

  --chart-background: rgb(51, 51, 51);
  --chart-color: #fff;

  --chart-handle-fill: #2c343a;
  --chart-handle-stroke: #67c2e4;
  --chart-handle-drag-fill: transparent;
  --chart-handle-dots: #67c2e4;
  --chart-handle-line: #67c2e4;
}

